import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontStyle: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 14,
    paddingHorizontal: 3,
    borderLeft: 'none',
    borderRight: 'none',
  },
});

const PdfTableRows = ({ columns, rows }) => (
  <>
    {rows.map((row) => (
      <View style={styles.row} key={row.id}>
        {columns.map((column) => (
          <Text
            style={{ width: column.width, textAlign: column.textAlign }}
            key={`${row.id}${column.name}`}
          >
            {row[column.name]}
          </Text>
        ))}
      </View>
    ))}
  </>
);

PdfTableRows.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
};

export default PdfTableRows;
