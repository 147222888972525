import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import { FdTypography, FdTooltip, FdIcons } from '@fifthdomain/fe-shared';
import { Gauge } from '../Charts';

const GaugeChartDetails = ({
  individualAverage,
  poolAverage,
  toolTipHeading,
  toolTip,
  valueInPercentage,
  chartId,
  enableAnimations,
  myAverageLabel,
  timeInHours,
}) => {
  const { InfoOutlined } = FdIcons;
  const percentageSymbol = `${valueInPercentage ? '%' : ''}`;

  return (
    <Box display="flex" flexDirection="column">
      <Gauge
        data={[individualAverage, poolAverage]}
        percentageSymbol={percentageSymbol}
        chartId={chartId}
        enableAnimations={enableAnimations}
      />
      <Box display="flex" flexDirection="column" alignItems="center" mt={-14}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <FdTypography variant="subtitle2">{toolTipHeading}</FdTypography>
          <FdTooltip title={toolTip}>
            <IconButton edge="end">
              <InfoOutlined />
            </IconButton>
          </FdTooltip>
        </Box>
        <FdTypography variant="body2" color="secondary">
          {`${myAverageLabel}: ${individualAverage}${percentageSymbol} ${timeInHours}`}
        </FdTypography>
        <FdTypography variant="body2" color="secondary">
          {`Pool average: ${poolAverage}${percentageSymbol} ${timeInHours}`}
        </FdTypography>
      </Box>
    </Box>
  );
};

GaugeChartDetails.propTypes = {
  individualAverage: PropTypes.number.isRequired,
  poolAverage: PropTypes.number.isRequired,
  toolTipHeading: PropTypes.string.isRequired,
  toolTip: PropTypes.string.isRequired,
  valueInPercentage: PropTypes.bool,
  chartId: PropTypes.string,
  enableAnimations: PropTypes.bool,
  myAverageLabel: PropTypes.string,
  timeInHours: PropTypes.string,
};

GaugeChartDetails.defaultProps = {
  valueInPercentage: false,
  chartId: 'gauge-chart',
  enableAnimations: true,
  myAverageLabel: 'My average',
  timeInHours: '',
};

export default GaugeChartDetails;
