import domtoimage from 'dom-to-image';

const convertChartToImage = async (chartId) => {
  return new Promise((resolve, reject) => {
    const chartElement = document.getElementById(chartId);
    // Convert the chart container to an image
    domtoimage
      .toPng(chartElement)
      .then((dataUrl) => {
        chartElement.style.display = 'none';
        resolve(dataUrl);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default convertChartToImage;
