import React from 'react';
import { Svg, Path } from '@react-pdf/renderer';

const TrophyIconYellow = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      d="M51.5556 6.44444H45.1111V0H12.8889V6.44444H6.44444C2.9 6.44444 0 9.34444 0 12.8889V16.1111C0 24.3278 6.18667 31.03 14.1456 32.0289C16.1756 36.8622 20.5256 40.5033 25.7778 41.5667V51.5556H12.8889V58H45.1111V51.5556H32.2222V41.5667C37.4744 40.5033 41.8244 36.8622 43.8544 32.0289C51.8133 31.03 58 24.3278 58 16.1111V12.8889C58 9.34444 55.1 6.44444 51.5556 6.44444ZM6.44444 16.1111V12.8889H12.8889V25.1978C9.15111 23.8444 6.44444 20.3 6.44444 16.1111ZM51.5556 16.1111C51.5556 20.3 48.8489 23.8444 45.1111 25.1978V12.8889H51.5556V16.1111Z"
      fill="#EFA029"
    />
  </Svg>
);

export default TrophyIconYellow;
