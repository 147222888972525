import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, Document } from '@react-pdf/renderer';
import ApexCharts from 'apexcharts';
import shortid from 'shortid';
import styles from './Styles';
import PdfHeader from './PdfHeader';
import { getCommaSeparated } from '../../shared/utils/stringUtils';
import TrophyIconYellow from './svg/TrophyIconYellow';
import PdfTable from './PdfTable';

const LearningPdfReport = ({
  candidateName,
  learningHours,
  specialtyAreasLearned,
  imageOne,
  treeMapData,
  cyberAreaData,
  gaugeValues,
  logoUrl,
  orgName,
  hideGaugeGraphs,
}) => {
  const [avgLabTimeChartURL, setAvgLabTimeChartURL] = useState(undefined);
  const [overallCourseChartURL, setOverallCourseChartURL] = useState(undefined);

  useEffect(() => {
    const getDataUri = async (chartId, setChartUrl) => {
      try {
        const url = await ApexCharts.exec(chartId, 'dataURI').then(
          ({ imgURI }) => imgURI,
        );
        setChartUrl(url);
      } catch {
        // eslint-disable-next-line no-console
        console.log('Error in generating gauge chart');
      }
    };
    getDataUri('avg-lab-time-chart-download', setAvgLabTimeChartURL);
    getDataUri('overall-course-chart-download', setOverallCourseChartURL);
  }, []);
  // gauge values
  const individualLabRunningTime = gaugeValues?.labRunningTime?.individual;
  const poolLabRunningTime = gaugeValues?.labRunningTime?.pool;
  const individualOverallCourseProgress =
    gaugeValues?.overallCourseProgress?.individual;
  const poolOverallCourseProgress = gaugeValues?.overallCourseProgress?.pool;

  const tableColumns = [
    {
      name: 'cyberArea',
      label: 'Cyber Area',
      width: '49%',
      textAlign: 'left',
    },
    {
      name: 'totalQuestions',
      label: 'Total          Questions', // space added by purpose for pdf alignment
      width: '17%',
      textAlign: 'center',
    },
    {
      name: 'attemptedQuestions',
      label: 'Attempted Questions',
      width: '17%',
      textAlign: 'center',
    },
    {
      name: 'completionRate',
      label: 'Completion rate',
      width: '17%',
      textAlign: 'center',
    },
  ];
  // comparison text
  const labTimeHours = individualLabRunningTime - poolLabRunningTime;
  const labTimeMoreLess = Math.sign(labTimeHours) === -1 ? 'less' : 'more';
  const labTimeHoursText = Math.abs(labTimeHours);

  const courseCompletionHours =
    individualOverallCourseProgress - poolOverallCourseProgress;
  const courseCompletionMoreLess =
    Math.sign(courseCompletionHours) === -1 ? 'less' : 'more';
  const courseCompletionHoursText = Math.abs(courseCompletionHours);

  const comparisonTextLabHours =
    labTimeHours !== 0
      ? `I spent ${labTimeHoursText} ${labTimeMoreLess} than the average time spent in lab environments as part of my cyber learning.`
      : '';
  const comparisonCourseCompletion =
    courseCompletionHours !== 0
      ? `My course completion percentage is ${courseCompletionHoursText} ${courseCompletionMoreLess} than the pool average.`
      : '';

  return (
    <Document>
      <Page size="A4">
        <PdfHeader
          bannerHeading={candidateName}
          bannerText="Learning Profile"
          logoUrl={logoUrl}
          orgName={orgName}
        />
        <View style={styles.pageContent}>
          <View style={styles.headingWithBadge}>
            <Text style={styles.sectionHeading}>Courses completed</Text>
            <View style={styles.badge}>
              <Text style={styles.badgeText}>{learningHours}</Text>
            </View>
          </View>
          <View style={styles.line} />
          <View style={styles.spacer} />
          {specialtyAreasLearned?.length > 0 ? (
            <View>
              {specialtyAreasLearned?.map((t) => (
                <View style={styles.courseRow} key={shortid.generate()}>
                  <View style={styles.courseRowContainer}>
                    <View style={styles.icon}>
                      <TrophyIconYellow />
                    </View>
                    <Text style={styles.courseRowHeading}>{t.courseName}</Text>
                  </View>
                  <View style={styles.courseAreaLearnt}>
                    <Text style={styles.text}>
                      {`Areas learnt: ${getCommaSeparated(t.specialtyAreas)}`}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          ) : (
            <View>
              <Text style={{ ...styles.text, textAlign: 'center' }}>
                When you complete courses, they will get listed here.
              </Text>
            </View>
          )}
        </View>
        <View style={styles.pageContent} break>
          <View style={styles.spacer} />
          <View style={styles.headingWithBadge}>
            <Text style={styles.sectionHeading}>Cyber skills learned</Text>
          </View>
          <View style={styles.line} />
          {treeMapData?.children?.length > 0 ? (
            <View>
              <View style={{ paddingTop: 5 }}>
                <View style={{ paddingBottom: 5 }}>
                  <Text style={styles.text}>
                    {`This is a tree map representing cyber specialty areas covered in courses, and the learning for each of those specialty areas demonstrated by the ${candidateName}`}
                  </Text>
                </View>
                <View style={styles.bulletPoint}>
                  <Text style={styles.bulletSymbol}>•</Text>
                  <Text style={styles.bulletText}>
                    The size of the box represents the weightage of the
                    specialty area across courses.
                  </Text>
                </View>
                <View style={styles.bulletPoint}>
                  <Text style={styles.bulletSymbol}>•</Text>
                  <Text style={styles.bulletText}>
                    The strength of the color blue represents the completion of
                    learning through quizzes.
                  </Text>
                </View>
              </View>
              <View style={styles.treeMapContainer}>
                <View style={{ width: '60%' }}>
                  {imageOne && <Image src={imageOne} />}
                </View>
                <View style={{ width: '40%', paddingTop: 10, paddingLeft: 5 }}>
                  {treeMapData?.children.map((t, index) => (
                    <Text id={index} style={styles.text}>
                      {`${t.slno}. ${t.name}`}
                    </Text>
                  ))}
                </View>
              </View>
              <View>
                <Text style={styles.sectionHeading}>
                  Top areas of successful learning
                </Text>
                <PdfTable columns={tableColumns} rows={cyberAreaData} />
              </View>
            </View>
          ) : (
            <View style={{ paddingTop: 5 }}>
              <Text style={styles.text}>
                When you attempt quizzes in a course, the learnings demonstrated
                are captured here.
              </Text>
            </View>
          )}
        </View>
        <View style={styles.pageContent} break>
          <View style={styles.pageSpacer}>
            <View style={styles.headingWithBadge}>
              <Text style={styles.sectionHeading}>Comparison to pool</Text>
            </View>
            <View style={styles.line} />
            {hideGaugeGraphs ? (
              <View style={{ paddingTop: 5 }}>
                <Text style={styles.text}>
                  As you start spending time in lab environment in courses, your
                  lab engagement is captured here.
                </Text>
              </View>
            ) : (
              <View>
                <View style={{ paddingTop: 5 }}>
                  <View>
                    <Text style={styles.text}>{comparisonTextLabHours}</Text>
                    <Text style={styles.text}>
                      {comparisonCourseCompletion}
                    </Text>
                  </View>
                  <View style={styles.gaugeContainer}>
                    <View style={{ width: '50%' }}>
                      <View style={{ paddingBottom: 10 }}>
                        <Text style={styles.gaugeRowHeading}>
                          Average lab time spent
                        </Text>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.text}>My average:</Text>
                          </View>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.gaugeTextBold}>
                              {individualLabRunningTime}
                            </Text>
                          </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.text}>Pool average:</Text>
                          </View>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.gaugeTextBold}>
                              {poolLabRunningTime}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: '50%' }}>
                      {avgLabTimeChartURL && (
                        <Image
                          style={styles.gaugeGraph}
                          src={avgLabTimeChartURL}
                        />
                      )}
                    </View>
                  </View>
                  <View style={styles.blueLine} />
                  <View style={styles.gaugeContainer}>
                    <View style={{ width: '50%' }}>
                      <View style={{ paddingBottom: 10 }}>
                        <Text style={styles.gaugeRowHeading}>
                          Average course completion
                        </Text>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.text}>My average:</Text>
                          </View>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.gaugeTextBold}>
                              {individualOverallCourseProgress}
                            </Text>
                          </View>
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.text}>Pool average:</Text>
                          </View>
                          <View style={styles.gaugeRow}>
                            <Text style={styles.gaugeTextBold}>
                              {poolOverallCourseProgress}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: '50%' }}>
                      {overallCourseChartURL && (
                        <Image
                          style={styles.gaugeGraph}
                          src={overallCourseChartURL}
                        />
                      )}
                    </View>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerLine} />
          <View style={styles.footerContainer}>
            <Text>&#xA9; Fifth Domain Pty Ltd</Text>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber}/${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

LearningPdfReport.propTypes = {
  candidateName: PropTypes.string.isRequired,
  learningHours: PropTypes.string.isRequired,
  specialtyAreasLearned: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  imageOne: PropTypes.string.isRequired,
  treeMapData: PropTypes.shape({
    children: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  cyberAreaData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gaugeValues: PropTypes.shape({
    labRunningTime: PropTypes.shape(
      PropTypes.shape({
        individual: PropTypes.string,
        pool: PropTypes.string,
      }),
    ),
    overallCourseProgress: PropTypes.shape(
      PropTypes.shape({
        individual: PropTypes.string,
        pool: PropTypes.string,
      }),
    ),
  }).isRequired,
  logoUrl: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  hideGaugeGraphs: PropTypes.bool.isRequired,
};

export default LearningPdfReport;
