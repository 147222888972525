import React, { useState } from 'react';
import Color from 'color';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FdTypography } from '@fifthdomain/fe-shared';
import TreeMap from '../Charts/TreeMap';

const LearningTreeMap = ({ id, treeMapData, showLabel }) => {
  const [treeMapCaption, setTreeMapCaption] = useState({});

  return (
    <Box width="100%">
      <TreeMap
        id={id}
        showLabel={showLabel}
        data={treeMapData}
        onMouseEnter={(node) => setTreeMapCaption(node.data)}
        onMouseLeave={() => setTreeMapCaption({})}
        colors={(node) => {
          const startColor = '#99E7FF';
          const endColor = '#00C2FF';
          // generate colors in between based on the value in %
          return Color(startColor)
            .mix(Color(endColor), Number(node.data.value) / 100)
            .hex();
        }}
      />
      {!showLabel && (
        <Box height="30px" mt={2}>
          {treeMapCaption?.name ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
            >
              <FdTypography variant="subtitle2">
                {treeMapCaption.name}
              </FdTypography>
              <Box display="flex" mt={1}>
                <FdTypography variant="body2" color="secondary">
                  Completion rate :
                </FdTypography>
                <Box ml={1}>
                  <FdTypography variant="body2">
                    {`${treeMapCaption.customLegend}%`}
                  </FdTypography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center">
              <FdTypography variant="body1" color="secondary">
                Hover over an area to see more detail.
              </FdTypography>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

LearningTreeMap.propTypes = {
  treeMapData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string,
  showLabel: PropTypes.bool,
};

LearningTreeMap.defaultProps = {
  id: 'treeMapChart',
  showLabel: false,
};
export default LearningTreeMap;
