import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@material-ui/core';
import { FdButton, FdTooltip } from '@fifthdomain/fe-shared';

const DownloadButton = forwardRef((props, ref) => {
  const { toolTipText, ...otherProps } = props;
  const Button = (
    <FdButton size="medium" ref={ref} {...otherProps}>
      Download PDF
    </FdButton>
  );

  return toolTipText ? (
    <FdTooltip title={toolTipText}>
      <span>{Button}</span>
    </FdTooltip>
  ) : (
    Button
  );
});

DownloadButton.propTypes = {
  toolTipText: PropTypes.string,
};
DownloadButton.defaultProps = {
  toolTipText: '',
};

const PdfReportDownload = ({ loading, url, reportName, toolTipText }) => {
  const buttonRef = React.createRef();
  return loading ? (
    <Box display="flex" width="100%" justifyContent="center" m={2}>
      <CircularProgress />
    </Box>
  ) : (
    <DownloadButton
      ref={buttonRef}
      href={url}
      download={`${reportName}.pdf`}
      toolTipText={toolTipText}
    />
  );
};

PdfReportDownload.propTypes = {
  loading: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  toolTipText: PropTypes.string,
};

PdfReportDownload.defaultProps = {
  toolTipText: '',
};

export default PdfReportDownload;
