import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  useTheme,
} from '@material-ui/core';
import _ from 'lodash';
import { differenceInMilliseconds } from 'date-fns';
import { gql, useQuery, useSubscription } from '@apollo/client';
import singleSpa from 'single-spa';
import {
  BasePage,
  FdTypography,
  FdLoadingSpinner,
  FdSkeleton,
  FdCard,
  FdIcons,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdButton,
} from '@fifthdomain/fe-shared';
import { TrophyRoomCardParticipant } from '@fifthdomain/competitions';
import { getDuration } from '../shared/utils/dateUtils';
import {
  listCourseUsers,
  getSystemTime,
  listUserScoreboardsByUserId,
  listUserMetricsByUserId,
} from '../graphql/queries';
import { productStyle } from '../shared/utils/layout';
import { onUpdateAssessment } from '../graphql/subscriptions';
import { getRandomImage } from '../shared/utils/images';
import {
  CONTENT_IMAGES,
  SKILLS_PERFORMANCE_TIMELINE_START_DATE,
} from '../constants';
import { getCourseProgressStatus } from '../shared/utils/taskUtils';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import { listUserAssessmentsLandingPage } from '../queries/customQueries';
import LabelWithTooltip from '../components/Contents/LabelWithTooltip';
import CyberBrain from '../components/Insights/Cortex/CyberBrain';
import HighlightedLabel from '../components/Contents/HighlightedLabel';
import AffiliationTag from '../components/Affiliated/AffiliationTag';
import AffiliationLinkStatusModal from '../components/Affiliated/LandingMessageModals/AffiliationLinkStatusModal';
import SquadTag from '../components/Affiliated/SquadTag';
import { calculatePercentageDifference } from '../shared/utils/objectUtils';
import PercentageDifferenceIndicator from '../components/PercentageDifferenceIndicator';
import CreatedChallenges from '../components/Contents/ChallengeContribution/CreatedChallenges';
import TrainingSvg from '../components/Training/TrainingSvg';
import Events from '../components/Contents/Events';

const Container = ({ children }) => (
  <Box className="flex items-center gap-x-2">{children}</Box>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
};

const ParticipantLandingPage = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const { Assignment, EmojiEvents, WbIncandescent, HomeOutlined } = FdIcons;
  const welcomeMessage = `Welcome, ${globalSnap.userName}!`;

  const {
    data: assessmentsData,
    loading: assessmentsLoading,
    refetch: refetchAssessmentsData,
  } = useQueryRecursive(gql(listUserAssessmentsLandingPage), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
  });

  const { data: listCourseUsersData, loading: listCourseUsersLoading } =
    useQueryRecursive(gql(listCourseUsers), {
      variables: {
        filter: {
          userId: { eq: globalSnap.userId },
        },
        status: {
          ne: 'REMOVED',
        },
        limit: 1000,
      },
    });

  const {
    data: userScoreboardsByUserIdData,
    loading: userScoreboardsByUserIdLoading,
  } = useQueryRecursive(gql(listUserScoreboardsByUserId), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
  });

  const {
    data: listUserMetricsByUserIdData,
    loading: listUserMetricsByUserIdLoading,
  } = useQueryRecursive(gql(listUserMetricsByUserId), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
  });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  useSubscription(gql(onUpdateAssessment), {
    onData: (_data) => {
      refetchAssessmentsData();
    },
  });

  const userScoreboards =
    userScoreboardsByUserIdData?.listUserScoreboardsByUserId?.items || [];
  const columnsToAverage = ['successScore', 'speed', 'efficiency', 'score'];
  const userAverage = _.reduce(
    userScoreboards,
    (result, item) => {
      _.forEach(columnsToAverage, (key) => {
        if (_.isNumber(item[key])) {
          // eslint-disable-next-line no-param-reassign
          result[key] = (result[key] || 0) + item[key];
        }
      });
      return result;
    },
    {},
  );
  _.forEach(userAverage, (value, key) => {
    userAverage[key] = parseFloat(
      (value / (userScoreboards?.length ?? 0)).toFixed(2),
    );
  });

  const { lastScore, lastSuccessScore, lastEfficiency, lastSpeed } =
    listUserMetricsByUserIdData?.listUserMetricsByUserId?.items[0] || {};

  userAverage.percentageDifferenceInScore =
    calculatePercentageDifference(lastScore, userAverage.score) || 0;
  userAverage.percentageDifferenceInSuccessScore =
    calculatePercentageDifference(lastSuccessScore, userAverage.successScore) ||
    0;
  userAverage.percentageDifferenceInEfficiency =
    calculatePercentageDifference(lastEfficiency, userAverage.efficiency) || 0;
  userAverage.percentageDifferenceInSpeed =
    calculatePercentageDifference(lastSpeed, userAverage.speed) || 0;

  // get expired status for started/non-started ones as per today
  const getCompletedStatus = (_status, _endDateTime, contentType) => {
    const isNotExpired =
      differenceInMilliseconds(
        new Date(_endDateTime),
        new Date(serverTime?.getSystemTime),
      ) > 0;
    return isNotExpired
      ? _status
      : contentType === 'competition'
      ? 'ENDED'
      : 'NOT_COMPLETED';
  };

  // all assessmentData for the user except REMOVED & ARCHIVED
  const listUserAssessmentsData =
    assessmentsData?.listUserAssessmentsByUserId?.items
      .filter((ad) => !['REMOVED'].includes(ad?.status))
      .filter((ad) => ad?.assessment?.status !== 'ARCHIVED');

  const allAssessments =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'ASSESSMENT')
      .map((ad) => ({
        ...ad,
        contentType: 'assessment',
        type: 'Solo',
        title: ad?.assessment?.name,
        duration: getDuration(ad.assessment.hours, ad.assessment.minutes),
        status:
          ad.status === 'STARTED'
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'assessment',
              )
            : ad.status,
        icon: (
          <Box style={productStyle('ASSESSMENT', theme)}>
            <Assignment style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('ASSESSMENT', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
        tasksCount: ad.assessment.tasks?.items?.length,
        eventStatus: getCompetitionStatus(
          ad.assessment?.startDateTime,
          ad.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
      })) || [];

  const coursesData =
    listCourseUsersData?.listCourseUsers?.items
      ?.filter(
        (userCourse) =>
          userCourse.course?.status === 'AVAILABLE' &&
          userCourse.course?.availability,
      )
      .sort(
        (a, b) =>
          new Date(a?.course?.createdAt) - new Date(b?.course?.createdAt),
      )
      .map((userCourse) => {
        const courseModulePartIds = userCourse?.course?.courseModules?.items
          .map((m) => m?.parts?.items.map((mp) => mp?.id))
          .flat();
        const partsProgress =
          userCourse?.modulePartProgresses?.items?.filter((mp) =>
            courseModulePartIds?.includes(mp?.modulePartId),
          ) || [];
        const { courseProgress, status } = getCourseProgressStatus({
          partsProgress,
          courseModulePartIds,
        });
        return {
          ...userCourse,
          contentType: 'course',
          title: userCourse?.course?.name,
          image: userCourse?.course?.image?.key,
          icon: (
            <Box style={productStyle()}>
              <WbIncandescent
                style={{
                  transform: 'rotateX(180deg)',
                  marginBottom: '5px',
                  height: '18px',
                }}
              />
            </Box>
          ),
          iconColor: productStyle('COURSE', theme)?.backgroundColor,
          unReadMessageCount: userCourse?.messages?.items?.filter(
            (m) => m.sender.id !== globalSnap?.userId,
          )?.length,
          courseModulesCount: userCourse?.course?.courseModules?.items?.length,
          courseProgress,
          status,
          preComputedStatus: true,
          eventStatus: 'STARTED',
        };
      }) || [];

  const allCompetitions =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'COMPETITION')
      .map((ad) => ({
        ...ad,
        contentType: 'competition',
        type: ad?.assessment?.teamBased ? 'Team' : 'Solo',
        title: ad?.assessment?.name,
        participantStatus:
          ['STARTED', 'NOT_STARTED'].includes(ad.status) &&
          ad.assessment?.endDateTime
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'competition',
              )
            : ad.status === 'FINISHED'
            ? 'ENDED'
            : ad.status,
        // status for competition refers to competition status not the participant status
        status: getCompetitionStatus(
          ad.assessment?.startDateTime,
          ad.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
        icon: (
          <Box style={productStyle('COMPETITION', theme)}>
            <EmojiEvents style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('COMPETITION', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
      })) || [];

  const allTrainings =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'TRAINING')
      .map((ad) => ({
        ...ad,
        contentType: 'training',
        type: 'Solo',
        title: ad?.assessment?.name,
        duration: getDuration(ad.assessment.hours, ad.assessment.minutes),
        status:
          ad.status === 'STARTED'
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'assessment',
              )
            : ad.status,
        icon: (
          <Box
            className="flex items-center justify-center w-full h-full"
            style={{
              ...productStyle('TRAINING', theme),
              height: '200px',
              width: '32px',
            }}
          >
            <TrainingSvg />
          </Box>
        ),
        iconColor: productStyle('TRAINING', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
        tasksCount: ad.assessment.tasks?.items?.length,
      })) || [];

  if (
    assessmentsLoading ||
    serverTimeLoading ||
    listCourseUsersLoading ||
    userScoreboardsByUserIdLoading ||
    listUserMetricsByUserIdLoading
  ) {
    return <FdLoadingSpinner />;
  }

  // all events excluding ENDED or NOT_COMPLETED or FINISHED
  const rows = [
    ...allAssessments,
    ...allCompetitions,
    ...coursesData,
    ...allTrainings,
  ]?.filter(
    (item) =>
      !['ENDED', 'NOT_COMPLETED', 'FINISHED', 'Completed']?.includes(
        item?.status,
      ) && item.eventStatus !== 'ENDED',
  );

  const competitionURL = ({ id, participantStatus }) =>
    participantStatus === 'NOT_STARTED'
      ? `/competitions/competition-start/${id}`
      : participantStatus === 'STARTED'
      ? `/competitions/competition-tasks/${id}`
      : `/competitions/competition-scoreboard/${id}`;

  const onEnterClick = ({ id, assessment, participantStatus, status }) => {
    const url =
      assessment?.participantEventType === 'ASSESSMENT'
        ? status === 'STARTED'
          ? `/assessor/assessment-tasks/${id}`
          : `/assessor/assessment-start/${id}`
        : assessment?.participantEventType === 'COMPETITION'
        ? competitionURL({ id, participantStatus })
        : assessment?.participantEventType === 'TRAINING'
        ? status === 'STARTED'
          ? `/competitions/training-tasks/${id}`
          : `/competitions/training-start/${id}`
        : `/labs/courses/view/${id}/`;
    return singleSpa.navigateToUrl(url);
  };

  // if only courses are assigned then navigate to learning else navigate to capability
  const mySkillsPathToNavigate =
    coursesData.length > 0 &&
    allAssessments.length === 0 &&
    allCompetitions.length === 0
      ? '/landing/learning'
      : '/landing/capability';

  const summaryData = [
    {
      value: (
        <Container>
          <Box className="flex justify-center items-center w-8 h-8 rounded-full ml-3">
            <HighlightedLabel value={Math.round(userAverage?.score) || 0} />
          </Box>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInScore}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="FifthDomain Score"
          tooltipText="Your FifthDomain score (0-100) is a combined weighted sum of your Success, Efficiency, and Speed scores, with Success carrying the highest weightage."
        />
      ),
    },
    {
      value: (
        <Container>
          <FdTypography variant="h4" style={{ fontWeight: 500 }}>
            {Math.round(userAverage?.successScore) || 0}
          </FdTypography>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInSuccessScore}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="Success Score"
          tooltipText="The Success Score (0-100) measures your ability to capture flags, taking into account points earned for solved challenges relative to overall points available."
        />
      ),
    },
    {
      value: (
        <Container>
          <FdTypography variant="h4" style={{ fontWeight: 500 }}>
            {Math.round(userAverage?.efficiency) || 0}
          </FdTypography>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInEfficiency}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="Efficiency Score"
          tooltipText="The Efficiency Score (0-100) measures your ability to score points in challenges while minimising the number of attempts made."
        />
      ),
    },
    {
      value: (
        <Container>
          <FdTypography variant="h4" style={{ fontWeight: 500 }}>
            {Math.round(userAverage?.speed) || 0}
          </FdTypography>
          <PercentageDifferenceIndicator
            value={userAverage?.percentageDifferenceInSpeed}
            suffix="last month"
          />
        </Container>
      ),
      description: (
        <LabelWithTooltip
          label="Speed Score"
          tooltipText="The Speed Score (0-100) reflects your quickness in completing challenges within the allocated time for an event."
        />
      ),
    },
  ];

  return (
    <BasePage data-cy="welcome-card">
      <AffiliationLinkStatusModal />
      <Box height={100} mb={theme.palette.type === 'dark' ? 4 : 3.5}>
        <FdCard variant="outlined">
          <Box className="flex justify-between items-center">
            <Box className="flex flex-col">
              <Box className="flex items-center mb-2">
                <HomeOutlined style={{ fontSize: 28, marginRight: '0.5rem' }} />
                <Box className="flex items-center gap-x-4">
                  <FdTypography variant="h3">{welcomeMessage}</FdTypography>
                  <AffiliationTag />
                  <SquadTag />
                </Box>
              </Box>
              <FdTypography variant="captiontext1" color="secondary">
                Welcome to your Home page! Here, you can enter your assigned
                events, explore quick overviews of your performance and skills,
                and more.
              </FdTypography>
            </Box>
            {globalSnap?.isAAFCOrg && (
              <FdButton
                size="small"
                variant="secondary"
                onClick={() => {
                  singleSpa.navigateToUrl(mySkillsPathToNavigate);
                }}
              >
                My skills profile
              </FdButton>
            )}
          </Box>
        </FdCard>
      </Box>
      <Box height={100} mb={2}>
        <Card variant="outlined">
          <CardContent style={{ padding: '1rem' }}>
            <Box className="flex items-center justify-around w-full">
              {summaryData?.map((d, idx) => (
                <>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex">{d?.value}</Box>
                    <Box display="flex" mt={1}>
                      <FdTypography variant="body2" color="secondary">
                        {d?.description}
                      </FdTypography>
                    </Box>
                  </Box>
                  {idx < summaryData.length - 1 && (
                    <Divider orientation="vertical" flexItem />
                  )}
                </>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Grid container spacing={2} style={{ marginTop: '-5px' }}>
        <Grid item xs={7}>
          <FdSkeleton
            loading={assessmentsLoading || listCourseUsersLoading}
            height={650}
          >
            <Events allEvents={rows} onEnterClick={onEnterClick} />
          </FdSkeleton>
          <Box my={2}>
            <TrophyRoomCardParticipant userId={globalSnap.userId} />
          </Box>
        </Grid>
        <Grid item xs={5}>
          <FdSkeleton
            loading={assessmentsLoading || listCourseUsersLoading}
            height="650px"
          >
            <FdCard
              elevation={0}
              heading={
                <Box display="flex" justifyContent="space-between">
                  <LabelWithTooltip
                    label="Skills Snapshot"
                    tooltipText="Your Skills Overview Page shows an accumulation of the skills and performance data you have demonstrated through your participation in assigned events."
                    labelVariant="body1"
                  />
                  <FdButton
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      singleSpa.navigateToUrl('/competitions/skills-overview');
                    }}
                  >
                    View Skills overview
                  </FdButton>
                </Box>
              }
            >
              <Box mb={3}>
                <FdTypography variant="captiontext2" color="secondary">
                  View a quick snapshot of your Cyber Skills Cortex below. To
                  delve deeper and use filtering and customisation options,
                  click the button located at the top right corner of this
                  section to access your full Skills Overview page, where you
                  can also view your Performance Triangle.
                </FdTypography>
              </Box>
              <Divider />
              <Box my={3}>
                <FdTypography variant="subtitle2">
                  Cyber Skills Cortex
                </FdTypography>
                <FdTypography variant="captiontext2" color="secondary">
                  The Cyber Skills Cortex displays demonstrated cyber skills
                  aligned with the Dreyfus model&apos;s five proficiency levels
                  (1-5). Mapped to one of six FifthDomain Professional
                  Specialties, each skill darkens in corresponding squares from
                  the centre of the Cortex as one&apos;s demonstration of
                  proficiency in that skill increases.
                </FdTypography>
                <Box mt={3} className="flex items-center justify-center">
                  <CyberBrain
                    userId={globalSnap.userId}
                    timeLine={{
                      startDate: SKILLS_PERFORMANCE_TIMELINE_START_DATE,
                      endDate: new Date(),
                    }}
                  />
                </Box>
              </Box>
            </FdCard>
            <CreatedChallenges />
          </FdSkeleton>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default ParticipantLandingPage;
