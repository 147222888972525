import { useEffect } from 'react';
import convertChartToImage from '../components/PdfReports/convertChartToImage';

const useChartImageCapture = (setChartImage, chartId) =>
  useEffect(() => {
    const generateChartImage = async () => {
      try {
        const image = await convertChartToImage(chartId);
        setChartImage(image);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error: generating image from chart');
      }
    };
    // Delay for finishing rendering the chart
    const timeoutId = setTimeout(generateChartImage, 500);
    return () => clearTimeout(timeoutId);
  }, [setChartImage, chartId]);

export default useChartImageCapture;
