import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, useTheme } from '@material-ui/core';
import {
  FdTable as Table,
  FdChip as Chip,
  FdIcons,
  FdProgress,
} from '@fifthdomain/fe-shared';
import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { getParticipantStatus } from '../../shared/utils/getParticipantStatus';

const ContentTable = ({ rows, courseOnly }) => {
  const { EmojiEvents } = FdIcons;
  const theme = useTheme();
  return (
    <Box height="445px" data-cy="content-table" width="100%">
      <Table
        id="content-table"
        toolbarSettings={{
          filterButton: true,
          searchBox: true,
        }}
        rows={rows}
        columnVisibilityModel={{
          trophyIcon: courseOnly,
          productIcon: !courseOnly,
        }}
        columns={[
          {
            field: 'trophyIcon',
            width: 60,
            headerName: '',
            searchable: false,
            filterable: false,
            renderCell: (params) => (
              <IconButton
                style={{
                  color:
                    params?.row?.status === 'FINISHED'
                      ? '#DA9E09' // colors hardcoded as it would be the same across themes
                      : theme.palette.iconography.secondary,
                }}
              >
                <EmojiEvents />
              </IconButton>
            ),
          },
          {
            field: 'productIcon',
            width: 60,
            headerName: '',
            searchable: false,
            filterable: false,
            renderCell: (params) => params.row.icon,
          },
          {
            field: 'name',
            width: 430,
            headerName: 'Name',
            valueGetter: (params) =>
              params?.row?.course?.name || params?.row?.assessment?.name,
          },

          {
            field: 'status',
            headerName: 'Status',
            width: 100,
            valueGetter: (params) => params?.row.status,
            renderCell: (params) => {
              return (
                <Chip
                  color={getParticipantStatusColor(params?.row.status)}
                  size="small"
                  label={getParticipantStatus(params?.row.status)}
                />
              );
            },
            sortComparator: (v1, v2, param1, param2) =>
              param1.value.localeCompare(param2.value),
          },
          {
            field: 'progressBar',
            flex: 1,
            headerName: 'Progress',
            searchable: false,
            filterable: false,
            valueGetter: (params) => params?.value,
            renderCell: (params) => (
              <FdProgress
                type="linear"
                value={params?.value?.progress}
                caption={params?.value?.progressCaption}
              />
            ),
          },
        ]}
        tablePageSize={4}
      />
    </Box>
  );
};

ContentTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  courseOnly: PropTypes.bool,
};

ContentTable.defaultProps = {
  courseOnly: false,
};

export default ContentTable;
