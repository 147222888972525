import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 50,
    textAlign: 'left',
    fontStyle: 'bold',
    flexGrow: 1,
    color: '#00AEEF',
    fontSize: 14,
    paddingHorizontal: 3,
    borderLeft: 'none',
    borderRight: 'none',
  },
});

const PdfTableHeader = ({ columns }) => (
  <View style={styles.container}>
    {columns.map((c) => (
      <Text
        style={{
          width: c.width,
          textAlign: c.textAlign,
        }}
        key={c.name}
      >
        {c.label}
      </Text>
    ))}
  </View>
);

PdfTableHeader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
};

export default PdfTableHeader;
