import { StyleSheet } from '@react-pdf/renderer';

const commonStyles = StyleSheet.create({
  text: {
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'justify',
    whiteSpace: 'nowrap',
  },
  logo: {
    width: 150,
    height: 100,
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
  line: {
    backgroundColor: '#001339',
    color: '#fff',
    paddingHorizontal: 40,
    padding: 0.5,
  },
  blueLine: {
    backgroundColor: '#1976D2',
    color: '#fff',
    paddingHorizontal: 40,
    padding: 0.1,
  },
  row: {
    flexDirection: 'row',
    paddingBottom: 20,
  },
  spacer: {
    margin: 10,
  },
  pageSpacer: {
    paddingVertical: 20,
  },
  graph: {
    paddingTop: 0,
    paddingBottom: 0,
    objectFit: 'cover',
  },
  footerLine: {
    backgroundColor: '#001339',
    color: '#fff',
    paddingHorizontal: 40,
    padding: 0.5,
    marginBottom: 5,
  },
  banner: {
    backgroundColor: '#001339',
    color: '#fff',
    paddingHorizontal: 40,
    padding: 20,
  },
  bannerHeading: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 800,
  },
  bannerText: {
    fontSize: 12,
    paddingVertical: 5,
  },
  bannerTitle: {
    color: '#00AEEF',
    fontSize: 10,
    letterSpacing: 3,
    padding: 20,
    paddingBottom: 30,
  },
  bannerLabel: {
    fontSize: 10,
    color: '#fff',
    paddingTop: 5,
  },
  bannerRow: {
    flexDirection: 'row',
    paddingLeft: 20,
    alignItems: 'flex-end',
  },
  textLabel: {
    fontStyle: 'bold',
    color: 'black',
    fontSize: 12,
  },
  textValue: {
    fontStyle: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
    paddingLeft: 5,
  },
  icon: {
    paddingRight: 5,
    paddingTop: 2,
  },
  textXS: {
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'justify',
    whiteSpace: 'nowrap',
  },
  textBoldMD: {
    fontSize: 14,
    color: '#001339',
  },
  textBoldXS: {
    fontSize: 10,
    color: '#001339',
  },
});

const styles = StyleSheet.create({
  ...commonStyles,
  pageContent: {
    paddingTop: 10,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  heading: {
    paddingBottom: 20,
    fontSize: 16,
  },
  sectionHeading: {
    fontSize: 16,
  },
  headingNotFirstPage: {
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: 16,
  },
  teamBasedFirstHeading: {
    paddingTop: 20,
    paddingBottom: 30,
    fontSize: 14,
    color: '#1976D2',
  },
  subHeading: {
    color: '#1976D2',
    paddingBottom: 5,
    fontSize: 14,
  },
  subHeadingNewPage: {
    color: '#1976D2',
    paddingBottom: 5,
    fontSize: 14,
    paddingTop: 25,
  },
  radarGraph: {
    padding: 0,
    margin: 0,
    height: 250,
    width: 525,
    objectFit: 'cover',
    top: -15,
  },
  gaugeGraph: {
    padding: 0,
    margin: 0,
    height: 200,
    width: 300,
    objectFit: 'cover',
  },
  percentageText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#1976D2',
  },
  topperName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  topperText: {
    paddingTop: 5,
    fontSize: 12,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  teamHeading: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  gaugeValue: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#1976D2',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
    borderBottom: '1px solid #1976D2',
  },
  fdLogoBox: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: 20,
    paddingBottom: 10,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bannerRowColumn: {
    flexDirection: 'column',
    padding: 20,
    paddingBottom: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  teamRowColumn: {
    flexDirection: 'column',
    paddingBottom: 0,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  footer: {
    position: 'absolute',
    bottom: 5,
    display: 'flex',
    width: '100%',
    paddingLeft: 20,
    paddingRight: 20,
  },
  footerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 11,
  },
  footerLinkColumn: {
    flex: 1,
  },
  taskRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 5,
  },
  taskRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  badge: {
    backgroundColor: '#001339',
    borderRadius: '4px',
    padding: 8,
    width: '150px',
    textAlign: 'center',
  },
  badgeText: {
    fontSize: 10,
    color: '#fff',
  },
  headingWithBadge: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 5,
  },
  courseRow: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 15,
  },
  courseRowContainer: {
    flexDirection: 'row',
    paddingBottom: 10,
    alignItems: 'center',
  },
  courseAreaLearnt: {
    display: 'flex',
    flexDirection: 'row',
  },
  courseRowHeading: {
    fontSize: 12,
    color: '#001339',
  },
  treeMapContainer: {
    paddingTop: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 30,
  },
  bulletPoint: {
    flexDirection: 'row',
    marginBottom: 5,
    marginLeft: 20,
  },
  bulletSymbol: {
    marginRight: 5,
    fontSize: 8,
    fontWeight: 'bold',
  },
  bulletText: {
    flex: 1,
    fontSize: 11,
    color: 'rgba(0, 0, 0, 0.54)',
    textAlign: 'justify',
    whiteSpace: 'nowrap',
  },
  gaugeContainer: {
    paddingTop: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 30,
    alignItems: 'center',
  },
  gaugeTextBold: {
    fontSize: 11,
    color: '#001339',
    paddingLeft: 5,
  },
  gaugeRow: {
    paddingTop: 10,
    paddingBottom: 0,
  },
  gaugeRowHeading: {
    fontSize: 14,
    color: '#001339',
  },
  radarChart: {
    padding: 0,
    margin: 0,
    height: 240,
    width: 250,
    objectFit: 'cover',
  },
  radarContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },
  gaugeContainerXS: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 0,
    marginBottom: 0,
  },
  gaugeGraphXS: {
    padding: 0,
    margin: 0,
    height: 100,
    width: 150,
    objectFit: 'cover',
  },
  gaugeGraphXSContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 0,
    marginBottom: 0,
  },
  gaugeRowHeadingXS: {
    fontSize: 10,
    color: '#001339',
  },
  gaugeTextBoldXS: {
    fontSize: 10,
    color: '#001339',
    paddingLeft: 5,
  },
  verticalLine: {
    borderLeft: '1px solid grey',
    height: 100,
  },
  stackColumns: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  nameValueContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingBottom: 20,
  },
  gaugePropertyValue: {
    ...commonStyles.textBoldXS,
    paddingLeft: 5,
  },
  capabilityIcon: {
    width: 15,
    height: 15,
    marginRight: 5,
  },
  participationProperty: {
    ...commonStyles.textBoldMD,
    width: 200,
  },
  participationPropertyValue: {
    ...commonStyles.textBoldMD,
    paddingLeft: 5,
  },
});

export default styles;
