import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box, Grid, useTheme } from '@material-ui/core';
import {
  FdTypography,
  FdCard,
  FdLoadingSpinner,
  FdIcons,
  useQueryRecursive,
  FdToggleButtons,
  BasePage,
  useSnapshot,
  globalStore,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import singleSpa from 'single-spa';
import ContentTable from '../components/SkillsProfile/ContentTable';
import { sortedByDate } from '../shared/utils/dateUtils';
import { productStyle } from '../shared/utils/layout';
import GaugeChartDetails from '../components/SkillsProfile/GaugeChartDetails';
import { Radar } from '../components/Charts';
import { listTaskAttemptAggregatesForCapabilityProfile } from '../queries/customQueries';
import { listUserAssessments } from '../graphql/queries';
import CapabilityPdfReportDownload from '../components/PdfReports/CapabilityPdfReportDownload';

const CapabilityProfile = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const [viewSkills, setViewSkills] = useState('capability-view');
  const { Assignment, EmojiEvents } = FdIcons;

  const {
    data: assessmentsNotStartedData,
    loading: assessmentsNotStartedLoading,
  } = useQueryRecursive(gql(listUserAssessments), {
    variables: {
      filter: {
        userId: { eq: globalSnap.userId },
        or: [
          { status: { eq: 'FINISHED' } },
          { status: { eq: 'NOT_STARTED' } },
          { status: { eq: 'STARTED' } },
        ],
      },
      limit: 1000,
    },
  });

  const { data: taskAttemptsData, loading: taskAttemptsDataLoading } =
    useQueryRecursive(gql(listTaskAttemptAggregatesForCapabilityProfile));

  if (assessmentsNotStartedLoading || taskAttemptsDataLoading) {
    return <FdLoadingSpinner />;
  }

  const taskAttempts = taskAttemptsData?.listTaskAttemptAggregates?.items || [];
  const userTaskAttempts =
    taskAttempts?.filter((a) => a.userId === globalSnap.userId) || [];
  const taskAttemptsDataAggregate = [
    ...userTaskAttempts,
    ...(assessmentsNotStartedData?.listUserAssessments?.items || []),
  ];

  // radar data
  const chartData = _(taskAttemptsDataAggregate)
    .filter(
      (ad) =>
        !['NOT_STARTED', 'REMOVED', 'ARCHIVED', 'FINISHED'].includes(
          ad?.status,
        ),
    )
    .groupBy((item) => _.get(item, 'task.specialty.name'))
    .map((groupedAttempts, specialty) => {
      const attempts = _.sumBy(groupedAttempts, 'attempts');
      const success = _.sumBy(groupedAttempts, (item) =>
        item.status === 'COMPLETED' ? 1 : 0,
      );
      return { specialty, attempts, success };
    })
    .filter((c) => c.specialty !== 'undefined')
    .value();

  const chatDataSorted = chartData?.sort(
    (a, b) => a.specialty?.localeCompare(b.specialty) || 0,
  );
  const chartCategories = chatDataSorted?.map((d) => d.specialty);
  const chartCategoryData = chatDataSorted?.map((d) =>
    d.attempts > 0 ? Math.round((d.success / d.attempts) * 100) : 0,
  );

  // prepare table data
  const tableRows = _(taskAttemptsDataAggregate)
    .filter((i) => i?.assessment)
    .groupBy((item) => item?.assessment?.id)
    .map((groupedAttempts) => {
      const { assessment } = groupedAttempts[0];
      const {
        multiLevel,
        levels,
        tasks,
        id,
        name: assessmentName,
        participantEventType,
        updatedAt,
      } = assessment;
      const icon =
        participantEventType === 'COMPETITION' ? (
          <Box style={productStyle('COMPETITION', theme)}>
            <EmojiEvents style={{ height: '18px' }} />
          </Box>
        ) : (
          <Box style={productStyle('ASSESSMENT', theme)}>
            <Assignment style={{ height: '18px' }} />
          </Box>
        );
      // if multi level compute total points from all available levels
      const assessmentTotalPoints = multiLevel
        ? _.sumBy(levels?.items, (level) =>
            _.sumBy(
              level?.tasks?.items,
              (item) => item.task?.recommendedPoints,
            ),
          )
        : _.sumBy(tasks?.items, (item) => item.task?.recommendedPoints);
      const pointsScored = groupedAttempts.reduce((total, item) => {
        if (item.status === 'COMPLETED') {
          return total + (item.points || 0);
        }
        return total;
      }, 0);
      const hasFinished = groupedAttempts.some((a) => a.status === 'FINISHED');

      return {
        id,
        assessment: { name: assessmentName },
        status: hasFinished
          ? 'FINISHED'
          : groupedAttempts.some((a) =>
              ['COMPLETED', 'ATTEMPTED', 'STARTED'].includes(a.status),
            )
          ? 'STARTED'
          : 'NOT_STARTED',
        assessmentTotalPoints,
        points: pointsScored,
        icon,
        updatedAt,
        participantEventType,
      };
    })
    .value();
  const tableRowsWithProgress = tableRows
    ?.filter((ad) => !['REMOVED', 'ARCHIVED'].includes(ad?.status))
    .map((r) => ({
      ...r,
      progressBar: {
        progress:
          r.assessmentTotalPoints > 0
            ? Math.round((r.points / r.assessmentTotalPoints) * 100)
            : 0,
        progressCaption: `${r.points}/${r.assessmentTotalPoints} points achieved`,
      },
    }));

  // gauge chart Data
  // solves and accuracy of the current participant
  const allSolves = chartData?.reduce((acc, i) => acc + i.success, 0);
  const allAttempts = chartData?.reduce((acc, i) => acc + i.attempts, 0);
  const attemptAccuracy =
    allAttempts > 0 ? Math.round((allSolves / allAttempts) * 100) : 0;
  // get all assessments where the current participant has competed - this is the pool
  const poolAssessmentIds = taskAttempts
    ?.filter((a) => a.userId === globalSnap.userId)
    ?.map((a) => a.assessment?.id);
  // filter attempts which belong to the pool
  const poolTaskAttempts =
    taskAttempts?.filter((t) => poolAssessmentIds.includes(t.assessment?.id)) ||
    [];
  // group based on users on pool and find solves/attempts
  const userWiseAttemptRows = _(poolTaskAttempts)
    .groupBy('userId')
    .map((groupedAttempts) => {
      const [{ userId }] = groupedAttempts; // get first element
      const attempts = _.sumBy(groupedAttempts, 'attempts');
      const success = _.sumBy(groupedAttempts, (item) =>
        item.status === 'COMPLETED' ? 1 : 0,
      );
      return { userId, attempts, success };
    })
    .value();

  // compute accuracy of each user in the pool
  const userWiseAttemptAccuracy = userWiseAttemptRows?.map((u) => ({
    ...u,
    accuracy: u.attempts > 0 ? Math.round((u.success / u.attempts) * 100) : 0,
  }));
  // pool accuracy in percentage
  const poolAttemptAccuracy = Math.round(
    (userWiseAttemptAccuracy?.reduce((acc, i) => acc + i.accuracy, 0) ?? 0) /
      (userWiseAttemptAccuracy?.length ?? 1),
  );

  // pool success average
  const poolSuccessAverage = Math.round(
    (userWiseAttemptAccuracy?.reduce((acc, i) => acc + i.success, 0) ?? 0) /
      (userWiseAttemptAccuracy?.length ?? 1),
  );

  // pdf download data
  const getEventAttemptCount = (eventType) =>
    tableRowsWithProgress?.filter(
      (t) =>
        ['STARTED', 'FINISHED'].includes(t.status) &&
        t.participantEventType === eventType,
    )?.length || 0;
  const totalCompetitionsAttempted = getEventAttemptCount('COMPETITION');
  const totalAssessmentsAttempted = getEventAttemptCount('ASSESSMENT');
  const totalChallengesAttempted = chartData?.reduce(
    (acc, i) => acc + i.attempts,
    0,
  );
  const totalSuccessfulChallenges = chartData?.reduce(
    (acc, i) => acc + i.success,
    0,
  );
  const totalSuccessRate =
    totalChallengesAttempted > 0
      ? Math.round((totalSuccessfulChallenges / totalChallengesAttempted) * 100)
      : 0;
  const pdfTableData = chartData
    ?.map((c) => ({
      id: c.specialty,
      specialty: c.specialty,
      successRate:
        c.attempts > 0 ? Math.round((c.success / c.attempts) * 100) : 0,
    }))
    .filter((s) => s.successRate > 0)
    .sort((a, b) => b.successRate - a.successRate)
    .slice(0, 5); // top 5
  // pdf gauge values
  const gaugeValues = {
    attemptSuccess: {
      individual: allSolves,
      pool: poolSuccessAverage,
    },
    attemptAccuracy: {
      individual: attemptAccuracy,
      pool: poolAttemptAccuracy,
    },
  };

  // Show my skills only to AAFC
  if (!globalSnap?.isAAFCOrg) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  return (
    <BasePage
      heading="My Skills Profile"
      breadCrumbs={[{ name: 'Home', url: '/landing/landing-homepage' }]}
      currentPageBreadcrumbLabel="My Skills Profile"
      linkComponent={RouterLink}
      data-cy="learning-base-page"
    >
      <Box display="flex" justifyContent="flex-end" mt="-57px" mb={2}>
        <Box ml={1}>
          <FdToggleButtons
            id="toggle-view"
            options={[
              {
                key: 'learning-view',
                content: 'Learning',
                disabled: false,
              },
              {
                key: 'capability-view',
                content: 'Capability',
                disabled: false,
              },
            ]}
            defaultValues={viewSkills}
            onChange={({ value }) => {
              setViewSkills(value);
              singleSpa.navigateToUrl('/landing/learning');
            }}
            buttonTextCapitalize
            flexWidth
          />
        </Box>
      </Box>
      <FdCard variant="outlined">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" height="12px">
            <FdTypography variant="h3">My Capabilities</FdTypography>
          </Box>
          <Box display="flex" alignItems="center" height="12px">
            <FdDelayed delay={3000}>
              <CapabilityPdfReportDownload
                candidateName={globalSnap.name}
                totalCompetitionsAttempted={totalCompetitionsAttempted}
                totalAssessmentsAttempted={totalAssessmentsAttempted}
                totalChallengesAttempted={totalChallengesAttempted}
                totalSuccessRate={totalSuccessRate}
                pdfTableData={pdfTableData}
                gaugeValues={gaugeValues}
                hideGaugeGraphs={userTaskAttempts?.length === 0}
              />
            </FdDelayed>
          </Box>
        </Box>
      </FdCard>
      <Grid container spacing={1} alignItems="stretch" direction="row">
        <Grid item xs={12} md={7}>
          <Box height="100%">
            <FdCard heading=" Assigned content" variant="outlined">
              <Box
                height="440px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {tableRowsWithProgress?.length > 0 ? (
                  <ContentTable
                    rows={sortedByDate(
                      (tableRowsWithProgress ?? []).map((row) => ({
                        ...row,
                      })),
                    )}
                  />
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <FdTypography variant="body1" color="secondary">
                      When you are assigned a
                      <br />
                      competition or an assessment,
                      <br />
                      it will populate here.
                    </FdTypography>
                  </Box>
                )}
              </Box>
            </FdCard>
            <FdCard
              heading="My performance average compared to others"
              variant="outlined"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                minHeight="279px"
              >
                {userTaskAttempts?.length > 0 ? (
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="space-around"
                    flexWrap="wrap"
                  >
                    <GaugeChartDetails
                      individualAverage={allSolves}
                      poolAverage={poolSuccessAverage}
                      toolTipHeading="Successful solves"
                      toolTip="Your total number of challenges successfully solved
                  compared to average successful solves of the pool."
                      chartId="attemptSuccess-gauge"
                      enableAnimations={false}
                      myAverageLabel="My solves"
                    />
                    <GaugeChartDetails
                      individualAverage={attemptAccuracy}
                      poolAverage={poolAttemptAccuracy}
                      toolTipHeading="Attempt accuracy"
                      toolTip="Ratio of successful solves to total attempts."
                      chartId="attemptAccuracy-gauge"
                      valueInPercentage
                      enableAnimations={false}
                    />
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                  >
                    <FdTypography variant="body1" color="secondary">
                      As you start attempting the competitions and/or
                      assessments,
                      <br />
                      your performance average populates here.
                    </FdTypography>
                  </Box>
                )}
              </Box>
            </FdCard>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <FdCard
            heading="Cyber skills snapshot"
            subHeading="My strength in various skills as demonstrated through my performance in the attempted competitions and/or assessments."
            variant="outlined"
            style={{ height: '100%' }}
          >
            <Box
              minHeight="720px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              {taskAttemptsDataAggregate?.length > 0 ? (
                <Box
                  display="flex"
                  width="100%"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Radar
                    data={[
                      {
                        name: '',
                        data: chartCategoryData || [],
                      },
                    ]}
                    categories={chartCategories || []}
                    chartId="radar-cyber-skills-chart"
                  />
                  <Box>
                    <FdTypography variant="body1" color="secondary">
                      Hover over the dots to see success rate for the category.
                    </FdTypography>
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <FdTypography variant="body1" color="secondary">
                    As you start attempting the competitions and/or
                    <br />
                    assessments,the strength you demonstrate in various
                    <br />
                    cyber categories get presented here.
                  </FdTypography>
                </Box>
              )}
            </Box>
          </FdCard>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default CapabilityProfile;
