import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Page, Image, Text, View, Document } from '@react-pdf/renderer';
import ApexCharts from 'apexcharts';
import styles from './Styles';
import PdfHeader from './PdfHeader';
import PdfTable from './PdfTable';
import AssessmentsIcon from '../../resources/images/Assessments-icon.png';
import CompetitionsIcon from '../../resources/images/Competitions-icon.png';

const CapabilityPdfReport = ({
  candidateName,
  logoUrl,
  orgName,
  totalCompetitionsAttempted,
  totalAssessmentsAttempted,
  totalChallengesAttempted,
  totalSuccessRate,
  pdfTableData,
  gaugeValues,
  hideGaugeGraphs,
}) => {
  const [radarCyberSkillsChartURL, setRadarCyberSkillsChartURL] =
    useState(undefined);
  const [attemptSuccessChartURL, setAttemptSuccessChartURL] =
    useState(undefined);
  const [attemptAccuracyChartURL, setAttemptAccuracyChartURL] =
    useState(undefined);

  useEffect(() => {
    const getDataUri = async (chartId, setChartUrl) => {
      try {
        const url = await ApexCharts.exec(chartId, 'dataURI').then(
          ({ imgURI }) => imgURI,
        );
        setChartUrl(url);
      } catch {
        // eslint-disable-next-line no-console
        console.log('Error in generating gauge chart');
      }
    };
    getDataUri('radar-cyber-skills-chart', setRadarCyberSkillsChartURL);
    getDataUri('attemptSuccess-gauge', setAttemptSuccessChartURL);
    getDataUri('attemptAccuracy-gauge', setAttemptAccuracyChartURL);
  }, []);
  // gauge values
  const attemptSuccessIndividual = gaugeValues?.attemptSuccess?.individual;
  const attemptSuccessPool = gaugeValues?.attemptSuccess?.pool;
  const attemptAccuracyIndividual = gaugeValues?.attemptAccuracy?.individual;
  const attemptAccuracyPool = gaugeValues?.attemptAccuracy?.pool;

  const tableColumns = [
    {
      name: 'specialty',
      label: 'Top Performing                         Specialties', // space added by purpose to align in pdf
      width: '70%',
      textAlign: 'left',
    },
    {
      name: 'successRate',
      label: 'Success Rate(%)',
      width: '30%',
      textAlign: 'center',
    },
  ];
  // comparison text
  const correctFlagsDifference = attemptSuccessIndividual - attemptSuccessPool;
  const correctFlagsMoreLess =
    Math.sign(correctFlagsDifference) === -1 ? 'lower' : 'higher';
  const correctFlagsDifferenceText = Math.abs(correctFlagsDifference);

  const successRateDifference = attemptAccuracyIndividual - attemptAccuracyPool;
  const successRateMoreLess =
    Math.sign(successRateDifference) === -1 ? 'lower' : 'higher';
  const successRateDifferenceText = Math.abs(successRateDifference);

  const comparisonAverageText =
    successRateDifference !== 0
      ? `Compared to the pool average, my success rate to submit correct flags was ${successRateDifferenceText}% ${successRateMoreLess}`
      : '';
  const correctFlagsComparisonText =
    correctFlagsDifference !== 0
      ? `, where my total number of correct flags were ${correctFlagsMoreLess} than the average submission of correct flags by ${correctFlagsDifferenceText} difference.`
      : '';

  const getDisplayValue = (value) => (value > 0 ? value : '-');

  return (
    <Document>
      <Page size="A4">
        <PdfHeader
          bannerHeading={candidateName}
          bannerText="Capability Profile"
          logoUrl={logoUrl}
          orgName={orgName}
        />
        <View style={styles.pageContent}>
          <View style={styles.headingWithBadge}>
            <Text style={styles.sectionHeading}>Participation</Text>
          </View>
          <View style={styles.line} />
          <View style={styles.radarContainer}>
            <View style={styles.stackColumns}>
              <View style={styles.nameValueContainer}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Image src={CompetitionsIcon} style={styles.capabilityIcon} />
                  <Text style={styles.participationProperty}>
                    Total Competitions attempted:
                  </Text>
                </View>
                <Text style={styles.participationPropertyValue}>
                  {getDisplayValue(totalCompetitionsAttempted)}
                </Text>
              </View>
              <View style={styles.nameValueContainer}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Image src={AssessmentsIcon} style={styles.capabilityIcon} />
                  <Text style={styles.participationProperty}>
                    Total Assessments attempted:
                  </Text>
                </View>
                <Text style={styles.participationPropertyValue}>
                  {getDisplayValue(totalAssessmentsAttempted)}
                </Text>
              </View>
            </View>
            <View style={styles.stackColumns}>
              <View style={styles.nameValueContainer}>
                <Text style={styles.participationProperty}>
                  Total Challenges attempted:
                </Text>
                <Text style={styles.participationPropertyValue}>
                  {getDisplayValue(totalChallengesAttempted)}
                </Text>
              </View>
              <View style={styles.nameValueContainer}>
                <Text style={styles.participationProperty}>
                  Overall Success rate:
                </Text>
                <Text style={styles.participationPropertyValue}>
                  {totalSuccessRate > 0 ? `${totalSuccessRate}%` : '-'}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.headingWithBadge}>
            <Text style={styles.sectionHeading}>Cyber skills snapshot</Text>
          </View>
          <View style={styles.line} />
          {pdfTableData?.length > 0 ? (
            <View>
              <View style={{ paddingTop: 5 }}>
                <Text style={styles.text}>
                  The radar shows the success rate of solving challenges within
                  various cyber specialties in percentage, demonstrating
                  strength in that category.
                </Text>
              </View>
              <View style={styles.radarContainer}>
                <View style={{ width: '50%' }}>
                  {radarCyberSkillsChartURL && (
                    <Image
                      src={radarCyberSkillsChartURL}
                      style={styles.radarChart}
                    />
                  )}
                </View>
                <View style={{ width: '50%' }}>
                  <PdfTable columns={tableColumns} rows={pdfTableData || []} />
                </View>
              </View>
            </View>
          ) : (
            <View style={{ paddingTop: 5, height: 250 }}>
              <Text style={styles.text}>
                As you start attempting the competitions and/or assessments,the
                strength you demonstrate in various cyber categories get
                presented here.
              </Text>
            </View>
          )}
          <View style={styles.headingWithBadge}>
            <Text style={styles.sectionHeading}>Comparison with cohort</Text>
          </View>
          <View style={styles.line} />
          {hideGaugeGraphs ? (
            <View style={{ paddingTop: 5 }}>
              <Text style={styles.text}>
                As you start attempting the competitions and/or assessments,
                your performance average populates here.
              </Text>
            </View>
          ) : (
            <View>
              <View style={{ paddingTop: 5 }}>
                <Text style={styles.text}>
                  {comparisonAverageText}
                  {correctFlagsComparisonText}
                </Text>
              </View>
              <View style={styles.gaugeContainerXS}>
                <View style={styles.gaugeGraphXSContainer}>
                  <View style={{ paddingTop: 12 }}>
                    {attemptSuccessChartURL && (
                      <Image
                        src={attemptSuccessChartURL}
                        style={styles.gaugeGraphXS}
                      />
                    )}
                  </View>
                  <View style={{ paddingBottom: 12 }}>
                    <Text style={styles.gaugeRowHeadingXS}>
                      Successful solves
                    </Text>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.textXS}>My solves:</Text>
                      </View>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.gaugePropertyValue}>
                          {attemptSuccessIndividual}
                        </Text>
                      </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.textXS}>Pool average:</Text>
                      </View>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.gaugePropertyValue}>
                          {attemptSuccessPool}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.verticalLine} />
                <View style={styles.gaugeGraphXSContainer}>
                  <View style={{ paddingTop: 12 }}>
                    {attemptAccuracyChartURL && (
                      <Image
                        src={attemptAccuracyChartURL}
                        style={styles.gaugeGraphXS}
                      />
                    )}
                  </View>
                  <View style={{ paddingBottom: 12 }}>
                    <Text style={styles.gaugeRowHeadingXS}>
                      Attempt accuracy
                    </Text>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.textXS}>My average:</Text>
                      </View>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.gaugePropertyValue}>
                          {`${attemptAccuracyIndividual}%`}
                        </Text>
                      </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.textXS}>Pool average:</Text>
                      </View>
                      <View style={styles.gaugeRow}>
                        <Text style={styles.gaugePropertyValue}>
                          {`${attemptAccuracyPool}%`}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          )}
        </View>
        <View style={styles.footer} fixed>
          <View style={styles.footerLine} />
          <View style={styles.footerContainer}>
            <Text>&copy; Fifth Domain Pty Ltd</Text>
            <Text
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber}/${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

CapabilityPdfReport.propTypes = {
  candidateName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  totalCompetitionsAttempted: PropTypes.number.isRequired,
  totalAssessmentsAttempted: PropTypes.number.isRequired,
  totalChallengesAttempted: PropTypes.number.isRequired,
  totalSuccessRate: PropTypes.number.isRequired,
  pdfTableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  gaugeValues: PropTypes.shape({
    attemptSuccess: PropTypes.shape(
      PropTypes.shape({
        individual: PropTypes.string,
        pool: PropTypes.string,
      }),
    ),
    attemptAccuracy: PropTypes.shape(
      PropTypes.shape({
        individual: PropTypes.string,
        pool: PropTypes.string,
      }),
    ),
  }).isRequired,
  hideGaugeGraphs: PropTypes.bool.isRequired,
};

CapabilityPdfReport.defaultProps = {
  logoUrl: undefined,
};

export default CapabilityPdfReport;
