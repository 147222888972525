import _ from 'lodash';

export const getCourseProgressIndividual = ({ modulePartData, courseData }) => {
  const coursePartData = courseData
    ?.map((courseDataItem) => courseDataItem?.parts?.items)
    .flat();
  const coursePartDataLength = coursePartData?.length || 0;
  const modulePartDataLength =
    modulePartData?.filter((p) => p?.modulePart)?.length || 0;

  // Course Progress
  const individualCourseProgress =
    coursePartData?.length > 0
      ? Math.round((modulePartDataLength / coursePartDataLength) * 100)
      : 0;
  return {
    individualCourseProgress,
  };
};

export const getCourseProgress = ({ modulePartData, courseData }) => {
  const coursePartData = courseData
    ?.map((courseDataItem) => courseDataItem?.parts?.items)
    .flat();
  const coursePartDataLength = coursePartData?.length || 0;

  // get individual course progress
  const courseProgressAllUsers = _(
    modulePartData?.filter(
      (p) => p?.modulePart && p?.modulePart?.status !== 'DELETED',
    ) || [],
  )
    .groupBy('courseUserId')
    .map((items) => ({
      // Individual Course Progress
      progress:
        coursePartData?.length > 0
          ? Math.round(items.length / coursePartDataLength) * 100
          : 0,
    }))
    .value();
  const allUsersProgress =
    courseProgressAllUsers.reduce((acc, i) => acc + i.progress, 0) || 0;
  const allUsersLength =
    courseProgressAllUsers.length > 0 ? courseProgressAllUsers.length : 1;
  // overall progress for each course (all users average)
  return {
    individualCourseProgress: Math.round(allUsersProgress / allUsersLength),
  };
};

export const getCourseModuleProgressStatus = ({
  modulePartsProgress,
  totalModuleParts,
}) => {
  const partsFinished =
    modulePartsProgress?.filter((p) => p.status === 'FINISHED').length || 0;
  let status = 'NOT STARTED';
  if (partsFinished > 0) {
    status = 'STARTED';
  }
  if (partsFinished > 0 && partsFinished === totalModuleParts) {
    status = 'FINISHED';
  }
  return status;
};
