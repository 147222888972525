import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from '@react-pdf/renderer';
import LogoInlineOverlaySVG from '../../resources/images/logo/logo-overlay.png';
import styles from './Styles';

const PdfHeader = ({ logoUrl, bannerHeading, bannerText, orgName }) => {
  return (
    <View>
      <View style={styles.headerRow}>
        <View style={styles.fdLogoBox}>
          <Image style={styles.logo} src={logoUrl || LogoInlineOverlaySVG} />
        </View>
        <View>
          <Text>{orgName}</Text>
        </View>
        <View style={styles.fdLogoBox}>
          <Image
            style={styles.logo}
            src={LogoInlineOverlaySVG}
            alt="Fifth Domain logo text with tagline 'securing cyber skill' below"
          />
        </View>
      </View>
      <View style={styles.banner}>
        <View style={styles.bannerRow}>
          <Text style={styles.bannerHeading}>{bannerHeading}</Text>
        </View>
        <View style={styles.bannerRow}>
          <Text style={styles.bannerText}>{bannerText}</Text>
        </View>
      </View>
    </View>
  );
};

PdfHeader.propTypes = {
  logoUrl: PropTypes.string,
  bannerHeading: PropTypes.string.isRequired,
  bannerText: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

PdfHeader.defaultProps = {
  logoUrl: undefined,
};

export default PdfHeader;
