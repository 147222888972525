import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ResponsiveTreeMap } from '@nivo/treemap';

const TreeMap = ({ id, data, showLabel, ...props }) => {
  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="center"
        style={{ height: '500px', margin: 'auto' }}
        id={id}
      >
        <ResponsiveTreeMap
          data={data}
          identity="name"
          value="value"
          innerPadding={2}
          outerPadding={2}
          margin={{
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
          }}
          label={(node) => (showLabel ? node.data.slno : '')}
          labelSkipSize={40}
          enableLabel={showLabel}
          orientLabel={false}
          labelTextColor={{ from: 'color', modifiers: [['darker', 2.5]] }}
          borderColor={{ from: 'color', modifiers: [['darker', 0.3]] }}
          isInteractive
          leavesOnly
          theme={{
            tooltip: {
              container: {
                background: '#333',
              },
            },
          }}
          tooltip={() => null}
          {...props}
        />
      </Box>
    </Box>
  );
};

TreeMap.propTypes = {
  id: PropTypes.string,
  data: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  showLabel: PropTypes.bool,
};

TreeMap.defaultProps = {
  id: undefined,
  showLabel: false,
};

export default TreeMap;
