import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box, Grid, makeStyles } from '@material-ui/core';
import singleSpa from 'single-spa';
import shortid from 'shortid';
import {
  FdTypography,
  FdCard,
  FdLoadingSpinner,
  FdIcons,
  useQueryRecursive,
  FdToggleButtons,
  BasePage,
  useSnapshot,
  globalStore,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import ContentTable from '../components/SkillsProfile/ContentTable';
import {
  listCourseUsers,
  listModulePartProgresses,
  listLabInstancesRemainingTime,
} from '../graphql/queries';
import { formatMinutes, sortedByDate } from '../shared/utils/dateUtils';
import { productStyle } from '../shared/utils/layout';
import GaugeChartDetails from '../components/SkillsProfile/GaugeChartDetails';
import {
  getCourseModuleProgressStatus,
  getCourseProgress,
  getCourseProgressIndividual,
} from '../shared/utils/courseUtils';
import { listQuizAttemptsByUserId } from '../queries/customQueries';
import LearningTreeMap from '../components/SkillsProfile/LearningTreeMap';
import LearningPdfReportDownload from '../components/PdfReports/LearningPdfReportDownload';

const useStyles = makeStyles((theme) => ({
  learningHoursWrapper: {
    backgroundColor:
      theme.palette?.type === 'light'
        ? '#E8EFF5'
        : theme?.palette?.background?.thumb,
    borderRadius: '4px',
  },
}));

const LearningProfile = () => {
  const globalSnap = useSnapshot(globalStore);
  const [viewSkills, setViewSkills] = useState('learning-view');
  const { WbIncandescent } = FdIcons;
  const [poolUserIds, setPoolUserIds] = useState([]);
  const styles = useStyles();

  const { data: userCoursesData, loading: userCoursesLoading } =
    useQueryRecursive(gql(listCourseUsers), {
      variables: {
        filter: {
          userId: {
            eq: globalSnap.userId,
          },
          status: {
            ne: 'REMOVED',
          },
        },
      },
      onCompleted: (_data) => {
        // set pool users
        setPoolUserIds(
          _(_data?.listCourseUsers?.items)
            .flatMap('course.courseUsers.items')
            .map((i) => i.userId)
            .uniq()
            .value(),
        );
      },
    });

  const { data: modulePartProgressData, loading: modulePartProgressLoading } =
    useQueryRecursive(gql(listModulePartProgresses), {
      variables: {
        filter: {
          userId: {
            eq: globalSnap.userId,
          },
          status: {
            ne: 'NOT_STARTED',
          },
        },
        limit: 1000,
      },
    });
  // pool progress
  const {
    data: poolModulePartProgressData,
    loading: poolModulePartProgressLoading,
  } = useQueryRecursive(gql(listModulePartProgresses), {
    variables: {
      filter: {
        or: poolUserIds.map((userId) => ({ userId: { eq: userId } })), // all users in the pool
      },
      limit: 1000,
    },
    skip: poolUserIds.length === 0,
  });

  // get Lab Remaining time from lab Instance for user
  const {
    data: listLabInstancesRemainingTimeData,
    loading: listLabInstancesRemainingTimeLoading,
  } = useQueryRecursive(gql(listLabInstancesRemainingTime), {
    variables: {
      filter: {
        userId: {
          eq: globalSnap.userId,
        },
      },
      limit: 1000,
    },
    skip: !globalSnap.userId,
  });

  // get Lab Remaining time from lab Instance for pool
  const {
    data: listPoolLabInstancesRemainingTimeData,
    loading: listPoolLabInstancesRemainingTimeLoading,
  } = useQueryRecursive(gql(listLabInstancesRemainingTime), {
    variables: {
      filter: {
        or: poolUserIds.map((userId) => ({ userId: { eq: userId } })), // all users in the pool
      },
      limit: 1000,
    },
    skip: poolUserIds.length === 0,
  });
  const { data: quizAttemptsData, loading: quizAttemptsLoading } =
    useQueryRecursive(gql(listQuizAttemptsByUserId), {
      variables: {
        userId: globalSnap.userId,
      },
    });

  const quizAttempts = quizAttemptsData?.listQuizAttemptsByUserId?.items || [];

  const countBySpecialtyArea = _.countBy(
    _.flatMap(
      quizAttempts.filter((q) => q.success),
      'question.competencies.items',
    ),
    'area.areaName',
  );

  const quizAttemptsBySpecialtyArea = _.map(
    countBySpecialtyArea,
    (count, specialtyArea) => ({
      specialtyArea,
      count,
    }),
  );

  const coursesDataItems = userCoursesData?.listCourseUsers?.items || [];
  const coursesData =
    coursesDataItems
      ?.filter(
        (userCourse) =>
          userCourse?.course?.status === 'AVAILABLE' &&
          userCourse?.course?.availability,
      )
      .map((userCourse) => {
        const courseModulePartIds = userCourse?.course?.courseModules?.items
          .map((m) => m?.parts?.items.map((mp) => mp?.id))
          .flat();
        // all parts progress
        const partsProgress =
          modulePartProgressData?.listModulePartProgresses?.items?.filter(
            (item) =>
              userCourse?.id === item?.courseUserId &&
              courseModulePartIds?.includes(item?.modulePartId),
          );

        // course progress
        const { individualCourseProgress } = getCourseProgressIndividual({
          modulePartData: partsProgress,
          courseData: userCourse?.course?.courseModules?.items,
        });
        const finishedModules =
          userCourse?.course?.courseModules?.items?.reduce((acc, i) => {
            // course module progress
            const moduleProgressStatus = getCourseModuleProgressStatus({
              modulePartsProgress: partsProgress?.filter(
                (p) => p?.modulePart?.courseModuleId === i?.id,
              ),
              totalModuleParts: i.parts?.items?.length || 0,
            });
            return acc + (moduleProgressStatus === 'FINISHED' ? 1 : 0);
          }, 0);
        // total modules in course
        const totalModules =
          userCourse?.course?.courseModules?.items?.length || 0;
        // total module duration
        const totalModuleDuration =
          userCourse?.course?.courseModules?.items?.reduce(
            (acc, i) => acc + (i.duration || 0),
            0,
          ) || 0;

        return {
          ...userCourse,
          status:
            individualCourseProgress === 100
              ? 'FINISHED'
              : individualCourseProgress > 0
              ? 'STARTED'
              : 'NOT STARTED',
          progressBar: {
            progress: individualCourseProgress,
            progressCaption: `${finishedModules}/${totalModules} Modules complete`,
          },
          icon: (
            <Box style={productStyle()}>
              <WbIncandescent
                style={{
                  transform: 'rotateX(180deg)',
                  marginBottom: '5px',
                  height: '18px',
                }}
              />
            </Box>
          ),
          totalModuleDuration,
        };
      }) || [];

  // overall course completion in percentage
  const overallCourseProgress =
    coursesData && coursesData?.length > 0
      ? Math.round(
          (coursesData?.reduce(
            (acc, i) => acc + (i.progressBar?.progress ?? 0),
            0,
          ) || 0) / coursesData.length,
        )
      : 0;

  // total learning hours across courses
  const learningHours =
    coursesData?.reduce(
      (acc, i) => acc + (i.status === 'FINISHED' ? i.totalModuleDuration : 0),
      0,
    ) || 0;
  // format using abbreviations
  const learningHoursFormatted = formatMinutes(learningHours, {
    hours: ' hrs',
    minutes: ' mins',
    seconds: ' secs',
  });

  const poolData =
    coursesDataItems
      ?.filter(
        (userCourse) =>
          userCourse?.course?.status === 'AVAILABLE' &&
          userCourse?.course?.availability,
      )
      .map((userCourse) => {
        const courseModulePartIds = userCourse?.course?.courseModules?.items
          .map((m) => m?.parts?.items.map((mp) => mp?.id))
          .flat();

        // all parts progress
        const partsProgress =
          poolModulePartProgressData?.listModulePartProgresses?.items?.filter(
            (item) =>
              userCourse?.courseId === item?.courseUser?.courseId &&
              courseModulePartIds?.includes(item?.modulePartId) &&
              item?.status !== 'NOT_STARTED',
          );
        // course progress
        const { individualCourseProgress } = getCourseProgress({
          modulePartData: partsProgress,
          courseData: userCourse?.course?.courseModules?.items,
        });
        return {
          progress: individualCourseProgress || 0,
        };
      }) || [];

  const overallPoolCourseProgress =
    poolData && poolData?.length > 0
      ? Math.round(
          poolData.reduce((acc, i) => acc + i.progress, 0) / poolData.length,
        )
      : 0;

  // lab running time
  const partProgress =
    modulePartProgressData?.listModulePartProgresses?.items?.filter((item) =>
      coursesData?.map((i) => i?.id)?.includes(item?.courseUserId),
    );
  const labInstancesForUser =
    listLabInstancesRemainingTimeData?.listLabInstances?.items?.filter((item) =>
      partProgress
        ?.filter((modulePart) => modulePart?.modulePart?.type === 'LAB')
        ?.map((i) => i?.modulePart?.labId)
        ?.includes(item.labPrototypeId),
    );
  const labRunningTime =
    labInstancesForUser?.reduce(
      (acc, i) => acc + Number(i.runningTime || 0),
      0,
    ) || 0;

  // pool lab running time
  const poolPartProgress =
    poolModulePartProgressData?.listModulePartProgresses?.items?.filter(
      (item) =>
        coursesData
          ?.map((i) => i?.courseId)
          ?.includes(item?.courseUser?.courseId),
    );
  const labInstancesForPool =
    listPoolLabInstancesRemainingTimeData?.listLabInstances?.items?.filter(
      (item) =>
        poolPartProgress
          ?.filter((modulePart) => modulePart?.modulePart?.type === 'LAB')
          ?.map((i) => i?.modulePart?.labId)
          ?.includes(item.labPrototypeId),
    );

  const poolLabRunningTime =
    labInstancesForPool && labInstancesForPool?.length > 0
      ? Math.round(
          labInstancesForPool.reduce(
            (acc, i) => acc + Number(i.runningTime || 0),
            0,
          ) / labInstancesForPool.length,
        )
      : 0;

  // all existing questions across all quizzes assigned
  const allQuizQuestions = _(coursesDataItems)
    .flatMap('course.courseModules.items')
    .flatMap('parts.items')
    .flatMap('quiz.questions.items')
    .flatMap('competencies.items')
    .groupBy('area.areaName')
    .map((items, specialtyArea) => ({
      specialtyArea,
      totalCount: items?.length,
    }))
    .filter(({ specialtyArea }) => specialtyArea !== 'undefined')
    .value();

  // tree map data representing the attempts with assigned
  const treeMapData = {
    name: 'Specialty Areas',
    children: allQuizQuestions?.map((q, index) => {
      const successfulQuizAttempts =
        quizAttemptsBySpecialtyArea.find(
          (a) => a.specialtyArea === q.specialtyArea,
        )?.count || 0;

      return {
        slno: index + 1,
        name: q.specialtyArea,
        value:
          successfulQuizAttempts > 0
            ? Math.round((successfulQuizAttempts / q.totalCount) * 100)
            : 10,
        customLegend:
          successfulQuizAttempts > 0
            ? Math.round((successfulQuizAttempts / q.totalCount) * 100)
            : 0,
      };
    }),
  };

  const finishedCourses = coursesData?.filter((c) => c.status === 'FINISHED');

  // specialty areas learned for each course for pdf
  const specialtyAreasLearned = finishedCourses?.map((c) => {
    const courseSpecialtyAreas =
      quizAttemptsData?.listQuizAttemptsByUserId?.items?.filter(
        (qa) => qa.courseId === c.courseId,
      );
    const specialtyAreas = [
      ...new Set(
        courseSpecialtyAreas?.flatMap((ct) =>
          ct.question.competencies.items.map(
            (t) => t.competency?.area?.areaName,
          ),
        ),
      ),
    ];
    return {
      courseId: c.courseId,
      courseName: c.course?.name,
      courseStatus: c.status,
      specialtyAreas,
    };
  });
  // pdf data table
  const cyberAreaData = quizAttemptsBySpecialtyArea?.map((a) => {
    const totalQuestions =
      allQuizQuestions.find((q) => q.specialtyArea === a.specialtyArea)
        ?.totalCount || 0;
    return {
      id: shortid.generate(),
      cyberArea: a.specialtyArea,
      totalQuestions,
      attemptedQuestions: a.count,
      completionRate: `${
        totalQuestions > 0 ? Math.round((a.count / totalQuestions) * 100) : 0
      }%`,
    };
  });

  if (
    userCoursesLoading ||
    modulePartProgressLoading ||
    listLabInstancesRemainingTimeLoading ||
    quizAttemptsLoading ||
    poolModulePartProgressLoading ||
    listPoolLabInstancesRemainingTimeLoading
  ) {
    return <FdLoadingSpinner />;
  }

  // Show my skills only to AAFC
  if (!globalSnap?.isAAFCOrg) {
    singleSpa.navigateToUrl('/landing/landing-homepage');
  }

  return (
    <BasePage
      heading="My Skills Profile"
      breadCrumbs={[{ name: 'Home', url: '/landing/landing-homepage' }]}
      currentPageBreadcrumbLabel="My Skills Profile"
      linkComponent={RouterLink}
      data-cy="learning-base-page"
    >
      <Box display="flex" justifyContent="flex-end" mt="-57px" mb={2}>
        <Box ml={1}>
          <FdToggleButtons
            id="toggle-view"
            options={[
              {
                key: 'learning-view',
                content: 'Learning',
                disabled: false,
              },
              {
                key: 'capability-view',
                content: 'Capability',
                disabled: false,
              },
            ]}
            defaultValues={viewSkills}
            onChange={({ value }) => {
              setViewSkills(value);
              singleSpa.navigateToUrl('/landing/capability');
            }}
            buttonTextCapitalize
            flexWidth
          />
        </Box>
      </Box>
      <FdCard variant="outlined">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center" height="12px">
            <FdTypography variant="h3">My Learning</FdTypography>
            <Box m={1} ml={3} p={1} className={styles.learningHoursWrapper}>
              <FdTypography variant="body1">{`Learning hours: ${learningHoursFormatted}`}</FdTypography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" height="12px">
            <FdDelayed delay={2000}>
              <LearningPdfReportDownload
                candidateName={globalSnap.name}
                learningHours={`Learning hours: ${learningHoursFormatted}`}
                specialtyAreasLearned={
                  specialtyAreasLearned?.filter(
                    (t) => t.specialtyAreas?.length > 0,
                  ) || []
                }
                treeMapData={treeMapData}
                cyberAreaData={cyberAreaData}
                gaugeValues={{
                  labRunningTime: {
                    individual: labRunningTime,
                    pool: poolLabRunningTime,
                  },
                  overallCourseProgress: {
                    individual: overallCourseProgress,
                    pool: overallPoolCourseProgress,
                  },
                }}
                hideGaugeGraphs={overallCourseProgress === 0}
              />
            </FdDelayed>
          </Box>
        </Box>
      </FdCard>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={12} md={7}>
          <FdCard heading="My courses" variant="outlined">
            <Box
              height="440px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {coursesData?.length > 0 ? (
                <ContentTable
                  rows={
                    coursesData &&
                    sortedByDate([
                      ...coursesData.map((row) => ({
                        ...row,
                      })),
                    ])
                  }
                  courseOnly
                />
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <FdTypography variant="body1" color="secondary">
                    When you are assigned a course,
                    <br />
                    it will populate here.
                  </FdTypography>
                </Box>
              )}
            </Box>
          </FdCard>
          <FdCard
            heading="My course engagement average compared to others"
            variant="outlined"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              minHeight="279px"
            >
              {overallCourseProgress === 0 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <FdTypography variant="body1" color="secondary">
                    As you start engaging with the courses,
                    <br />
                    your engagement average populates here.
                  </FdTypography>
                </Box>
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="space-around"
                  flexWrap="wrap"
                >
                  <GaugeChartDetails
                    individualAverage={labRunningTime}
                    poolAverage={poolLabRunningTime}
                    toolTipHeading="Average lab time spent"
                    toolTip="Time spent in lab environments as a 
                    part of learning compared to Lab time spent
                    by others for the same courses."
                    chartId="avg-lab-time-chart-download"
                    enableAnimations={false}
                    timeInHours="hours"
                  />
                  <GaugeChartDetails
                    individualAverage={overallCourseProgress}
                    poolAverage={overallPoolCourseProgress}
                    toolTipHeading="Average course completion"
                    toolTip="Engagement relative to completion
                    by others for the same courses"
                    chartId="overall-course-chart-download"
                    valueInPercentage
                    enableAnimations={false}
                  />
                  <Box style={{ height: '0px', overflow: 'hidden' }}>
                    <GaugeChartDetails
                      individualAverage={labRunningTime}
                      poolAverage={poolLabRunningTime}
                      chartId="avg-lab-time-chart-download"
                    />
                    <GaugeChartDetails
                      individualAverage={overallCourseProgress}
                      poolAverage={overallPoolCourseProgress}
                      chartId="overall-course-chart-download"
                      valueInPercentage
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </FdCard>
        </Grid>
        <Grid item xs={12} md={5}>
          <FdCard
            heading="Cyber skills learned"
            subHeading="My learning tree  on the basis of the skills learned through courses."
            variant="outlined"
          >
            <Box
              minHeight="805px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {treeMapData?.children?.length > 0 ? (
                <Box display="flex" flexDirection="column" width="100%">
                  <LearningTreeMap treeMapData={treeMapData} />
                  <Box width="100%" mt={50}>
                    <LearningTreeMap
                      treeMapData={treeMapData}
                      id="treeMapChartForPdfDownload"
                      showLabel
                    />
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <FdTypography variant="body1" color="secondary">
                    When you attempt quizzes in the courses assigned
                    <br />
                    to you, the tree map of your learning starts building up.
                  </FdTypography>
                </Box>
              )}
            </Box>
          </FdCard>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default LearningProfile;
