import React from 'react';
import PropTypes from 'prop-types';
import { View, StyleSheet } from '@react-pdf/renderer';
import PdfTableHeader from './PdfTableHeader';
import PdfTableRows from './PdfTableRows';

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginTop: 5,
    borderWidth: 1,
    borderColor: '#bff0fd',
    marginBottom: 15,
    borderLeft: 'none',
    borderRight: 'none',
  },
});

const PdfTable = ({ columns, rows, showHeader }) => (
  <View style={styles.tableContainer}>
    {showHeader && <PdfTableHeader columns={columns} />}
    <PdfTableRows columns={columns} rows={rows} />
  </View>
);

PdfTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  rows: PropTypes.array.isRequired,
  showHeader: PropTypes.bool,
};

PdfTable.defaultProps = {
  showHeader: true,
};

export default PdfTable;
