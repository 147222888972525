import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Storage } from 'aws-amplify';
import { usePDF } from '@react-pdf/renderer';
import useAsyncEffect from 'use-async-effect';
import { useSnapshot, globalStore } from '@fifthdomain/fe-shared';
import CapabilityPdfReport from './CapabilityPdfReport';
import PdfReportDownload from './PdfReportDownload';
import { listUrls } from '../../queries/customQueries';

const CapabilityPdfReportDownload = ({ ...props }) => {
  const [logoUrlData, setLogoUrlData] = useState({});
  const [s3Url, sets3url] = useState(null);
  const globalSnap = useSnapshot(globalStore);

  // generate PDF
  const [instance, updateInstance] = usePDF({
    document: (
      <CapabilityPdfReport
        {...props}
        logoUrl={s3Url}
        orgName={globalSnap.orgName}
      />
    ),
  });

  const { loading: urlsLoading } = useQuery(gql(listUrls), {
    variables: {
      filter: {
        orgId: { eq: globalSnap.orgId },
        theme: { eq: 'LIGHT' },
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (_data) => {
      setLogoUrlData(_data?.listUrls?.items[0]);
    },
    skip: !globalSnap.orgId,
  });

  const getImageUrlFromS3 = async (key) => {
    if (key === undefined) return null;
    try {
      const imageData = await Storage.get(key, {
        level: 'public',
      });
      return imageData;
    } catch (error) {
      return error.message;
    }
  };

  useAsyncEffect(
    async (isActive) => {
      if (logoUrlData) {
        const s3 = await getImageUrlFromS3(logoUrlData?.key);
        sets3url(s3);
        // eslint-disable-next-line no-useless-return
        if (!isActive()) return;
      }
    },
    [logoUrlData],
  );

  useEffect(() => {
    // update chart after url load
    if (s3Url) {
      updateInstance();
    }
  }, [s3Url]);

  return (
    <PdfReportDownload
      loading={urlsLoading || instance.loading}
      url={instance.url}
      reportName="My Capabilities"
      toolTipText="Download the PDF version of your Capability Profile"
    />
  );
};

export default CapabilityPdfReportDownload;
